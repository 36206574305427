@import "variables";
@import "mixins";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  display: none;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-color: rgba(37, 37, 37, 0.7);
  overflow: auto;

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    max-width: 100%;
    max-height: 75vh;
    padding: 55px 45px;
    box-sizing: border-box;
    background-color: #fff;
    overflow: auto;

    // @media only screen and (min-width: 1170px) and (max-height: 930px) {
    //   padding-top: 55px;
    //   padding-bottom: 55px;
    // }

    @include respond-to(laptop) {
      padding: 50px 40px;
    }

    @include respond-to(small_tablet) {
      padding: 40px 20px;
    }
  }

  &__title {
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: 0.03em;
    text-align: center;

    @include respond-to(laptop) {
      font-size: 2rem;
    }

    @include respond-to(small_tablet) {
      font-size: 1.4rem;
    }
  }

  &__subtitle {
    margin-top: 8px;
    text-align: center;
  }

  &-infograp {

    .popup__content {
      display: none;

      &.show {
        display: block;
      }
    }
  }

  &-answer {
    // display: block;
    color: $mineShaft;

    .popup__content {
      width: 570px;
      padding: 50px 100px;
      text-align: center;

      @include respond-to(small_tablet) {
        position: fixed;
        top: auto;
        left: auto;
        bottom: 0;
        transform: initial;
        width: 100%;
        /*height: 420px;*/
        max-height: 100vh;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .btn {
      margin-top: 25px;
      color: $mineShaft;
    }

    &__img {
      width: 120px;
      height: 132px;
      margin: 0 auto;
    }

    &__title {
      margin-top: 30px;
      font-size: 1.8rem;
      font-weight: 700;
      letter-spacing: 0.03em;
    }

    &__desc {
      margin-top: 15px;
    }
  }

  &-rules {

    .btn-close {

      @include respond-to(small_tablet) {
        right: 10px;
        width: 20px;
        height: 20px;
        background-image: url(../img/general/icon/icon_cross_black.svg);
      }
    }

    .popup {

      &__content {
        width: 825px;
        padding: 80px 100px 60px;

        @include respond-to(tablet) {
          width: 705px;
        }

        @include respond-to(small_tablet) {
          width: 100%;
          height: 100vh;
          max-height: initial;
          padding: 80px 15px 20px;
        }
      }

      &__title {
        font-size: 1.8rem;
      }
    }

    .text {
      margin-top: 15px;
      text-align: center;
    }
  }
}

.polluters {
  position: relative;
  display: grid;
  grid-template-columns: 242px 1fr;
  grid-gap: 25px;
  margin-top: 50px;

  @include respond-to(laptop) {
    grid-template-columns: 190px 1fr;
    grid-gap: 20px;
  }

  @include respond-to(small_tablet) {
    grid-template-columns: 1fr;
    margin-top: 30px;
    padding-bottom: 60px;
  }

  &__col {

    &:nth-child(2) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-gap: 25px;

      @include respond-to(laptop) {
        grid-gap: 20px;
      }
    }
  }

  &__row {
    display: flex;

    @include respond-to(small_tablet) {
      flex-wrap: wrap;
    }

    &:first-child {

      .polluters__item {

        &:nth-child(1),
        &:nth-child(2) {
          width: 30%;

          @include respond-to(small_tablet) {
            width: 25%;
          }

          @include respond-to(fablet) {
            width: 50%;
          }
        }

        &:nth-child(3),
        &:nth-child(4) {
          width: 20%;

          @include respond-to(small_tablet) {
            width: 25%;
          }

          @include respond-to(fablet) {
            width: 50%;
            margin-top: 30px;
          }
        }
      }
    }

    &:last-child {

      .polluters__item {
        width: 20%;

        @include respond-to(fablet) {
          width: calc(100% / 3);
        }

        &:nth-child(n + 4) {

          @include respond-to(fablet) {
            margin-top: 30px;
          }
        }
      }
    }
  }

  &__icon {
    margin: 0 auto;

    &--26p {
      width: 242px;
      width: 100%;
      height: 260px;

      @include respond-to(laptop) {
        height: 200px;
      }
    }

    &--10p {
      width: 150px;
      height: 150px;

      @include respond-to(laptop) {
        width: 120px;
        height: 120px;
      }
    }

    &--7p {
      width: 98px;
      height: 105px;

      @include respond-to(laptop) {
        width: 75px;
        height: 78px;
      }
    }

    &--6p {
      width: 84px;
      height: 88px;

      @include respond-to(laptop) {
        width: 64px;
        height: 68px;
      }
    }

    &--5p {
      width: 75px;
      height: 75px;

      @include respond-to(laptop) {
        width: 58px;
        height: 58px;
      }
    }

    &--4p {
      width: 60px;
      height: 60px;

      @include respond-to(laptop) {
        width: 47px;
        height: 47px;
      }
    }

    &--3p {
      width: 43px;
      height: 45px;

      @include respond-to(laptop) {
        width: 33px;
        height: 35px;
      }
    }
  }

  &__info {
    margin-top: 20px;
    text-align: center;
  }

  &__percent {
    font-size: 2.4rem;
    font-weight: 800;
    letter-spacing: 0.03em;

    @include respond-to(laptop) {
      font-size: 2rem;
    }
  }

  &__title {

    @include respond-to(laptop) {
      font-size: 1.2rem;
    }
  }

  &__sign {
    margin-top: 25px;

    @include respond-to(small_tablet) {
      position: absolute;
      left: 0;
      bottom: 0;
      margin-top: 0;
    }
  }
}

.fragments {
  display: grid;
  grid-template-columns: 1fr 540px;
  grid-gap: 65px;
  margin-top: 40px;

  @include respond-to(laptop) {
    grid-template-columns: 1fr 400px;
    grid-gap: 50px;
  }

  @include respond-to(small_tablet) {
    grid-template-columns: 200px 1fr;
    grid-gap: 30px;
  }

  @include respond-to(fablet) {
    grid-template-columns: 1fr;
  }

  &__col {

    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 120px;

      @include respond-to(fablet) {
        align-items: center;
        padding-top: 0;
      }
    }
  }

  &__title {
    align-self: flex-end;
    font-size: 6.4rem;
    font-weight: 800;
    letter-spacing: 0.03em;

    @include respond-to(laptop) {
      font-size: 5.4rem;
    }

    @include respond-to(fablet) {
      align-self: initial;
    }

    span {
      display: block;
      font-size: 2.4rem;
      line-height: 1;
      text-transform: initial;
    }
  }

  &__sign {

    @include respond-to(fablet) {
      margin-top: 20px;
    }
  }

  &__img {
    width: 445px;
    height: 516px;

    @include respond-to(laptop) {
      width: 100%;
      height: 400px;
    }

    @include respond-to(fablet) {
      height: 516px;
    }

    @include respond-to(phone) {
      height: auto;
    }
  }
}
