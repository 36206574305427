@import "variables";
@import "mixins";

.logo {
  display: block;
  width: 69px;
  height: 70px;

  @include respond-to(small_tablet) {
    width: 59px;
    height: 60px;
  }
}

.share {
  display: flex;
  align-items: center;

  &__item {
    cursor: pointer;
    
    &:not(:first-child) {
      margin-left: 30px;
    }

    &:hover {

      path {
        fill: $keppel;
      }
    }

    path {
      transition: $basicTransition;
    }
  }
}

.menu {
  position: fixed;
  top: 0;
  right: -500px;
  z-index: 300;
  width: 475px;
  max-width: 100%;
  height: 100vh;
  padding: 130px 45px 20px;
  box-sizing: border-box;
  background-color: $yellow;
  transition: $basicTransition;

  @media only screen and (min-width: 1170px) and (max-height: 930px) {
    padding-top: 100px;
  }

  @include respond-to(tablet) {
    padding-top: 100px;
  }

  @include respond-to(small_tablet) {
    padding: 80px 20px 20px;
  }

  @include respond-to(fablet) {
    right: -100%;
    width: 100vw;
  }

  &.show {
    right: 0;
  }

  // .btn-close {
  //   top: 30px;
  //   right: 30px;
  // }

  &__list {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    .share {
      justify-content: space-evenly;
      margin-top: auto;
      padding-top: 40px;
      // overflow: hidden;

      span {
        display: block;
      }
    }
  }

  &__item {
    display: block;
    padding: 20px 30px;
    border-bottom: 1px solid $mineShaft;
    font-family: $raleway;
    font-size: 1.8rem;
    line-height: 150%;
    color: $mineShaft;
    text-transform: uppercase;
    transition: $basicTransition;

    @include respond-to(small_tablet) {
      padding: 20px 15px;
      font-size: 1.8rem;
    }

    &:hover,
    &.current {
      color: $keppel;
    }

    &.initial {

      &:hover {
        color: $mineShaft;
      }
    }

    &-hashtag {
      font-size: 1.2rem;
    }

    &-link {
      display: block;
      margin-top: 20px;
      font-family: $raleway;
      font-size: 1.8rem;
      line-height: 150%;
      color: $mineShaft;
      text-transform: uppercase;
      transition: $basicTransition;
  
      @include respond-to(small_tablet) {
        font-size: 1.8rem;
      }

      &:hover,
      &.current {
        color: $keppel;
      }

      &.disable {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
}

