@import "variables";
@import "mixins";

.btn {
  display: inline-block;
  min-width: 170px;
  height: 40px;
  padding: 0 30px;
  border: 2px solid $keppel;
  border-radius: 31px;
  box-sizing: border-box;
  font-family: $raleway;
  font-size: 1.4rem;
  line-height: 38px;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
  color: $white;
  transition: $basicTransition;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: $keppel;
  }

  &-read {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: '';
      display: block;
      width: 12px;
      height: 10px;
      margin-left: 10px;
      background-image: url(../img/general/icon/icon_arrow.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &-rules {
    display: block;
    width: 32px;
    height: 32px;
    cursor: pointer;

    &:hover {

      path {
        fill: $ming;
      }

      circle {
        stroke: $ming;
      }
    }
  }

  &-menu {
    position: relative;
    display: block;
    width: 40px;
    height: 30px;
    cursor: pointer;

    &:hover {

      span {
        background-color: $ming;
      }
    }

    span {
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background-color: $keppel;
      transition: $basicTransition;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: calc(50% - 1px);
      }

      &:nth-child(3) {
        top: calc(100% - 2px);
      }
    }
  }

  &-close {
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 10;
    width: 40px;
    height: 40px;
    background-image: url(../img/general/icon/icon_cross.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;

    @include respond-to(small_tablet) {
      top: 10px;
      right: 20px;
    }

    &--black {
      background-image: url(../img/general/icon/icon_cross_black.svg);
    }
  }
}

