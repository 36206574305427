@import "variables";
@import "mixins";

.quiz {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // padding: 260px 0 60px;
  padding-bottom: 60px;
  box-sizing: border-box;

  @media only screen and (min-width: 1170px) and (max-height: 930px) {
    // padding-top: 95px;
    padding-bottom: 30px;
  }

  @include respond-to(laptop) {
    padding-bottom: 40px;
  }

  .section__img {
    //display: none;
  }

  &__intro {
    position: relative;
    z-index: 10;
    // display: none;
    padding-top: 200px;

    @media only screen and (min-width: 1170px) and (max-height: 930px) {
      padding-top: 95px;
    }

    @include respond-to(laptop) {
      padding-top: 230px;
    }

    @include respond-to(small_tablet) {
      padding-top: 270px;
    }

    .btn {
      margin-top: 40px;

      @include respond-to(phone) {
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 1.4;
      }
    }

    &-title {
      font-size: 9.2rem;
      font-weight: 900;
      letter-spacing: 0.03em;

      @include respond-to(laptop) {
        font-size: 6.4rem;
      }

      @include respond-to(small_tablet) {
        font-size: 4.8rem;
      }
    }

    &-desc {
      margin-top: 20px;
    }
  }

  &__content {
    display: none;
    padding-top: 130px;

    @include respond-to(laptop) {
      padding-top: 110px;
    }

    .quiz__polluters {

      @include respond-to(tablet) {
        grid-template-columns: 1fr;
      }

      &-item {

        @include respond-to(tablet) {
          display: none;
        }

        &::before {

          @include respond-to(tablet) {
            width: 200px;
            height: 200px;
          }

          @include respond-to(small_tablet) {
            width: 120px;
            height: 120px;
          }
        }

        &.current {

          @include respond-to(tablet) {
            display: flex;
          }
        }
      }

      &-img {

        @include respond-to(tablet) {
          width: 167px;
          height: 167px;
        }

        @include respond-to(small_tablet) {
          width: 100px;
          height: 100px;
        }
      }

      &-body {

        @include respond-to(tablet) {
          margin-top: 80px;
        }

        @include respond-to(small_tablet) {
          margin-top: 50px;
        }
      }
    }
  }

  &__result {
    display: none;
    padding-top: 130px;

    @include respond-to(laptop) {
      padding-top: 110px;
    }

    .share {
      margin-left: 30px;

      @include respond-to(fablet) {
        justify-content: center;
        margin-top: 30px;
        margin-left: 0;
      }
    }
  }

  &__footer {
    margin-top: auto;
    padding-top: 40px;
    text-align: center;

    @include respond-to(laptop) {
      padding-top: 180px;
    }

    @include respond-to(small_tablet) {
      padding-top: 270px;
    }

    .text {
      font-size: 1rem;
      color: #666;
    }
  }

  &__polluters {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 10px;
    row-gap: 40px;

    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      &.current {

        &::before {
          background-color: $flamingo;
        }
      }

      &.passed {
        opacity: 0.3;
      }

      &::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: #333;
        transition: $basicTransition;

        @include respond-to(laptop) {
          width: 100px;
          height: 100px;
        }
      }
    }

    &-img {
      position: relative;
      z-index: 10;
      width: 100px;
      height: 100px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      @include respond-to(laptop) {
        width: 80px;
        height: 80px;
      }

      &--cup {
        background-image: url(../img/content/quiz/icon_001.svg);
      }

      &--tin-bank {
        background-image: url(../img/content/quiz/icon_002.svg);
      }

      &--al-bank {
        background-image: url(../img/content/quiz/icon_003.svg);
      }

      &--napkin {
        background-image: url(../img/content/quiz/icon_004.svg);
      }

      &--papper {
        background-image: url(../img/content/quiz/icon_005.svg);
      }

      &--bottle {
        background-image: url(../img/content/quiz/icon_006.svg);
      }

      &--swab {
        background-image: url(../img/content/quiz/icon_007.svg);
      }

      &--diaper {
        background-image: url(../img/content/quiz/icon_008.svg);
      }

      &--butt {
        background-image: url(../img/content/quiz/icon_009.svg);
      }

      &--plastic {
        background-image: url(../img/content/quiz/icon_010.svg);
      }
    }

    &-body {
      margin-top: 50px;
    }

    &-title {
      position: relative;
      z-index: 10;
      font-size: 1.4rem;
      font-weight: normal;
      line-height: 130%;
      text-align: center;
    }

    &-desc {
      margin-top: 10px;
      font-size: 1.8rem;
      font-weight: 700;
      text-transform: initial;
      text-align: center;
      color: $matisse;
    }
  }
}

.score {
  width: 160px;
  height: 160px;
  padding-top: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  font-weight: 700;
  text-align: center;
  background-color: $matisse;

  @include respond-to(laptop) {
    width: 100px;
    height: 100px;
    padding-top: 10px;
  }

  @include respond-to(tablet) {
    margin: 0 auto;
  }

  @include respond-to(small_tablet) {
    width: 60px;
    height: 60px;
  }

  &__title {
    font-size: 1.8rem;

    @include respond-to(laptop) {
      text-transform: initial;
    }

    @include respond-to(small_tablet) {
      font-size: 1.2rem;
    }
  }

  &__count {
    margin-top: 10px;
    font-size: 4.8rem;

    @include respond-to(laptop) {
      margin-top: 4px;
      font-size: 3rem;
    }

    @include respond-to(small_tablet) {
      margin-top: 0;
      font-size: 1.8rem;
    }

    span {
      font-size: inherit;
    }
  }

  &--fixed {
    position: fixed;
    top: 130px;
    right: 100px;

    @media only screen and (min-width: 1170px) and (max-height: 930px) {
      right: 30px;
      width: 100px;
      height: 100px;
      padding-top: 10px;
    }

    @include respond-to(laptop) {
      position: relative;
      top: auto;
      right: auto;
      margin: 0 auto;
    }

    .score {

      &__title {

        @media only screen and (min-width: 1170px) and (max-height: 930px) {
          text-transform: initial;
        }
      }

      &__count {

        @media only screen and (min-width: 1170px) and (max-height: 930px) {
          margin-top: 4px;
          font-size: 3rem;
        }
      }
    }
  }
}

.answers {
  margin-top: 90px;

  @include respond-to(laptop) {
    margin-top: 80px;
  }

  @include respond-to(small_tablet) {
    margin-top: 60px;
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 0.03em;
    text-align: center;
  }

  &__list {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    margin-left: -12px;

    @include respond-to(laptop) {
      margin-top: 50px;
    }

    @include respond-to(small_tablet) {
      flex-wrap: wrap;
      margin-top: 30px;
      margin-left: 0;
    }

    &--xs {

      .answers__col {

        &--md {

          .answers {

            &__item {

              &:nth-child(2) {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }

  &__col {
    height: 140px;
    margin-left: 12px;

    @include respond-to(small_tablet) {
      height: auto;
      margin-left: 0;
    }

    &:not(:first-child) {

      @include respond-to(small_tablet) {
        margin-top: 20px;
      }
    }

    &--xl {
      width: 297px;

      @include respond-to(small_tablet) {
        width: 100%;
      }
    }

    &--md {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      flex-shrink: 0;
      width: 210px;

      @include respond-to(small_tablet) {
        justify-content: initial;
        width: 100%;
      }

      .answers {

        &__item {
          height: calc(50% - 7px);

          @include respond-to(small_tablet) {
            height: 60px;
          }
          
          &:last-child {
            margin-top: 14px;

            @include respond-to(small_tablet) {
              margin-top: 20px;
            }
          }

          &:nth-child(2) {
            margin-left: 12px;

            @include respond-to(small_tablet) {
              margin-top: 20px;
              margin-left: 0;
            }
          }

          &--mmd {
            align-self: flex-end;
            height: 43px;

            @include respond-to(small_tablet) {
              align-self: initial;
              height: 60px;
            }
          }

          &--xs,
          &--xxs {
            align-self: flex-end;
            height: 65px;

            @include respond-to(small_tablet) {
              align-self: initial;
              height: 60px;
            }
          }
        }
      }
    }

    &--xs {
      width: 180px;

      @include respond-to(small_tablet) {
        width: 100%;
      }
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 3px solid $keppel;
    border-radius: 70px;
    box-sizing: border-box;
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 150%;
    text-align: center;
    cursor: pointer;
    transition: $basicTransition;

    @include respond-to(small_tablet) {
      height: 60px;
    }

    &:hover {
      background-color: $keppel;
    }

    &.selected {
      background-color: $keppel;
    }

    &--md {
      width: 128px;

      @include respond-to(small_tablet) {
        width: 100%;
      }
    }

    &--mmd {
      width: 89px;

      @include respond-to(small_tablet) {
        width: 100%;
      }
    }

    &--xs {
      width: 70px;
      font-size: 1.2rem;

      @include respond-to(small_tablet) {
        width: 100%;
        font-size: 1.6rem;
      }
    }

    &--xxs {
      width: 70px;
      font-size: 1.3rem;

      @include respond-to(small_tablet) {
        width: 100%;
        font-size: 1.6rem;
      }
    }
  }
}

.question {

  @include respond-to(laptop) {
    margin-top: 40px;
  }

  @include respond-to(tablet) {
    margin-top: 80px;
  }

  @include respond-to(small_tablet) {
    margin-top: 40px;
  }
}

.result {

  &__header {
    display: flex;

    @include respond-to(tablet) {
      flex-direction: column;
    }

    &-item {

      &:not(:first-child) {
        margin-left: 40px;

        @include respond-to(tablet) {
          margin-top: 30px;
          margin-left: 0;
        }
      }
    }
  }

  &__title {

    @include respond-to(tablet) {
      text-align: center;
    }
  }

  &__text {
    margin-top: 30px;

    @include respond-to(tablet) {
      text-align: center;
    }
  }

  &__btn {
    display: flex;
    margin-top: 40px;

    @include respond-to(tablet) {
      justify-content: center;
      margin-top: 30px;
    }

    @include respond-to(fablet) {
      flex-direction: column;
    }

    .btn {

      &:not(:first-child) {
        margin-left: 20px;

        @include respond-to(fablet) {
          margin-top: 20px;
          margin-left: 0;
        }
      }
    }
  }

  &__body {
    margin-top: 70px;

    @include respond-to(tablet) {
      width: 400px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    @include respond-to(small_tablet) {
      margin-top: 60px;
    }
  }

  &__right {

    .quiz__polluters {
      row-gap: 50px;
      margin-top: 40px;

      @include respond-to(tablet) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 100px;
      }

      @include respond-to(phone) {
        grid-template-columns: 1fr;
        row-gap: 70px;
      }

      &-item {

        @include respond-to(phone) {
          display: grid;
          grid-template-columns: 120px 1fr;
          column-gap: 20px;
        }

        &::before {

          @include respond-to(phone) {
            left: 0;
            transform: initial;
            width: 120px;
            height: 120px;
          }
        }
      }

      &-img {

        @include respond-to(phone) {
          width: 100px;
          height: 100px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &-title {
        min-height: 36px;

        @include respond-to(phone) {
          min-height: initial;
          margin-top: initial;
          text-align: left;
        }
      }

      &-desc {

        @include respond-to(phone) {
          text-align: left;
        }
      }
    }

    &-title {
      font-size: 1.8rem;
      font-weight: 700;
      letter-spacing: 0.03;
      text-align: center;
    }
  }
}
