@import "variables";
@import "mixins";

.page {

  &-main {
    overflow: hidden;

    // .section {
    //   padding: 5.55vh 0;

    //   @include respond-to(laptop) {
    //     padding: 50px 0;
    //   }

    //   @include respond-to(small_tablet) {
    //     padding: 40px 0;
    //   }
    // }
  }

  &-article {
    counter-reset: count;

    .content {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      display: flex;

      @include respond-to(laptop) {
        position: relative;
        top: auto;
        left: auto;
        height: auto;
        display: block;
      }
    }

    .section {
      flex-shrink: 0;
      width: 100%;
      box-sizing: border-box;
    }
  }

  &-quiz {

    .header {
      position: absolute;
    }
  }
}

.section {
  position: relative;

  &__img {
    position: absolute;
    z-index: 5;

    .image-clip {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.65);
      }
    }

    &--left {
      top: 46%;
      left: 4%;

      @media only screen and (min-width: 1170px) and (max-height: 930px) {
        top: 35%;
        left: -12%;
      }

      @include respond-to(laptop) {
        top: 72%;
        left: -11%;
      }

      @include respond-to(small_tablet) {
        top: 75%;
        left: -7%;
      }

      @include respond-to(phone) {
        top: 78%;
        left: -17%;
      }
    }

    &--right {

      &-top {
        top: 13%;
        left: 75%;

        @media only screen and (min-width: 1170px) and (max-height: 930px) {
          top: 13%;
          left: 82%;
        }

        @include respond-to(laptop) {
          top: 12%;
          left: 75%;
        }

        @include respond-to(small_tablet) {
          top: 9%;
          left: 70%;
        }
      }

      &-bottom {
        top: 49%;
        left: 85%;

        @media only screen and (min-width: 1170px) and (max-height: 930px) {
          top: 68%;
          left: 86%;
        }

        @include respond-to(laptop) {
          top: 72%;
          left: 68%;
        }

        @include respond-to(small_tablet) {
          top: 77%;
        }
      }
    }
  }

  &__title {
    padding: 0 15px;
    font-family: $raleway;
    font-size: 4.8rem;
    font-weight: 800;
    line-height: 120%;
    text-transform: uppercase;
    text-align: center;

    @include respond-to(small_tablet) {
      font-size: 2.4rem;
    }

    &--md {
      font-size: 3.6rem;

      @include respond-to(laptop) {
        font-size: 2.4rem;
      }
    }
  }

  &__body {
    width: 850px;
    max-width: 100%;
    margin: 60px auto 0;
    padding: 0 100px;
    box-sizing: border-box;

    @include respond-to(small_tablet) {
      margin-top: 30px;
      padding: 0 15px;
    }

    & > p {

      &:not(:first-child) {
        margin-top: 30px;
      }
    }
  }

  &-count {
    counter-reset: count;
  }

  &--bg {
    color: $white;
    background-color: $mineShaft;
    overflow: hidden;
  }

  &--reset {
    padding-top: 0;
    padding-bottom: 0;

    @include respond-to(laptop) {
      padding-top: 75px;
      padding-bottom: 75px;
    }

    @include respond-to(small_tablet) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  &--default {
    padding: 120px 0;

    @include respond-to(laptop) {
      padding: 80px 0;
    }
  }

  &-waste {

    @include respond-to(phone) {
      overflow: hidden;
    }

    .slide__item {

      &:first-child {

        @media only screen and (min-width: 1170px) and (max-height: 930px) {
          padding-top: 50px;
        }

        .image-clip {

          @media only screen and (min-width: 1170px) and (max-height: 930px) {
            display: none;
          }
        }

        .slide__count {

          @media only screen and (min-width: 1170px) and (max-height: 930px) {
            margin-top: 0;
          }
        }
      }

      &:nth-child(2) {
        position: relative;
        padding-top: 200px;

        @media only screen and (min-width: 1170px) and (max-height: 930px) {
          display: block;
          padding-top: 180px;
        }

        @include respond-to(laptop) {
          margin-left: 130px;
          padding-top: 125px;
        }

        @include respond-to(fablet) {
          margin-left: 20px;
        }

        @include respond-to(phone) {
          margin-left: 0;
          padding-top: 0;
        }

        .image-clip {

          &:first-child {
            position: absolute;
            top: -9%;
            left: -12%;

            @media only screen and (min-width: 1170px) and (max-height: 930px) {
              top: 0;
              left: -3%;
            }

            @include respond-to(laptop) {
              top: 0;
            }

            @include respond-to(phone) {
              top: -68%;
              left: auto;
              right: -20px;
            }
          }

          &:nth-child(2) {
            position: absolute;
            top: 50%;
            left: -51%;

            @include respond-to(laptop) {
              top: 55%;
            }

            @include respond-to(phone) {
              top: 30%;
              left: 20%;
            }
          }

          &:last-child {

            @include respond-to(phone) {
              transform: translateX(140px);
            }
          }
        }
      }

      &:last-child {

        @include respond-to(laptop) {
          margin-top: 0;
        }

        @include respond-to(phone) {
          margin-top: -80px;
        }
      }
    }
  }
}

.teaser {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 5.55vh;
  padding-bottom: 5.55vh;
  box-sizing: border-box;
  text-align: center;

  @include respond-to(laptop) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @include respond-to(small_tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .logo {
    margin: 0 auto;
  }

  .share {
    justify-content: center;
    margin-top: 40px;

    @include respond-to(laptop) {
      margin-top: 16px;
    }

    @include respond-to(small_tablet) {
      margin-top: 40px;
    }
  }

  &__sign {
    margin-top: 20px;

    @include respond-to(small_tablet) {
      margin-top: 10px;
    }

    br {

      @include respond-to(phone) {
        display: none;
      }
    }
  }

  &__body {
    margin-top: 4.63vh;

    @include respond-to(laptop) {
      margin-top: 50px;
    }

    @include respond-to(small_tablet) {
      margin-top: 30px;
    }
  }

  &__title {
    font-size: 6.4rem;
    font-weight: 900;

    @media only screen and (min-width: 1440px) and (max-height: 1040px) {
      font-size: 4.4rem;
    }

    @include respond-to(desktop) {
      font-size: 3.056vw;
    }

    @include respond-to(laptop) {
      font-size: 4.8rem;
    }

    @include respond-to(small_tablet) {
      font-size: 3rem;
    }
  }

  &__desc {
    margin-top: 20px;

    @include respond-to(desktop) {
      font-size: 1.4rem;
    }

    @include respond-to(laptop) {
      font-size: 1.6rem;
    }
  }

  &__nav {
    margin-top: 60px;

    @include respond-to(laptop) {
      margin-top: 60px;
    }

    &-row {

      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    &-item {
      display: flex;
      align-items: center;

      @include respond-to(laptop) {
        flex-direction: column;
      }

      @include respond-to(fablet) {
        flex-direction: row;
      }
    }

    &-col {

      &:not(:first-child) {
        margin-left: 20px;

        @include respond-to(laptop) {
          margin-top: 20px;
          margin-left: 0;
        }

        @include respond-to(fablet) {
          margin-top: 0;
          margin-left: 20px;
        }
      }

      .teaser {

        &__nav {

          &-title {

            @include respond-to(fablet) {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
    
    &-img {
      position: relative;
      width: 120px;
      height: 12.22vh;
      min-height: 92px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      @media only screen and (min-width: 1440px) and (max-height: 1040px) {
        height: 10vh;
      }

      @include respond-to(laptop) {
        height: 132px;
        min-height: initial;
      }

      @include respond-to(fablet) {
        width: 110px;
      }

      &--trash {
        background-image: url(../img/general/icon/icon_trash.svg);
      }

      &--oil-waste {
        background-image: url(../img/general/icon/icon_oil_waste.svg);
      }

      &--trash-pile {
        background-image: url(../img/general/icon/icon_trash_pile.svg);
      }
    }

    &-title {
      font-size: 1.6rem;
      font-weight: 700;
      letter-spacing: 0.03em;
      text-align: left;
      color: $white;

      @media only screen and (min-width: 1440px) and (max-height: 1040px) {
        font-size: 1.6rem;
      }

      @include respond-to(desktop) {
        font-size: 1.6rem;
      }

      &.disable {
        pointer-events: none;
        opacity: 0.4;
      }
    }

    &-btn {
      margin-top: 15px;
      text-align: left;

      @include respond-to(laptop) {
        text-align: center;
      }

      @include respond-to(fablet) {
        text-align: left;
      }

      .btn {

        @include respond-to(fablet) {
          min-width: 140px;
        }
      }
    }
  }

  &__hashtag {
    margin-bottom: 30px;
    font-family: $raleway;
    font-size: 3rem;
    line-height: 120%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &__footer {
    margin-top: auto;
    padding-top: 5.55vh;

    @include respond-to(laptop) {
      padding-top: 75px;
    }

    @include respond-to(small_tablet) {
      padding-top: 55px;
    }

    .text {
      font-size: 1rem;
      color: #666;
    }
  }
}

.garbage {
  position: absolute;
  top: 0;
  height: 100%;
  overflow: hidden;

  &__img {
    position: absolute;
    top: -500px;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &--paper {
      background-image: url(../img/general/img_garbage_paper.svg);
      animation: fadeFallingDown 11s linear 2s infinite;
    }

    &--bank {
      background-image: url(../img/general/img_garbage_bank.svg);
      animation: fadeFallingDown 11s linear 6s infinite;
    }

    &--fish {
      background-image: url(../img/general/img_garbage_fish.svg);
      animation: fadeFallingDown 11s linear 9.5s infinite;
    }

    &--food {
      background-image: url(../img/general/img_garbage_food.svg);
      animation: fadeFallingDown 7s linear 2s infinite;
    }

    &--lamp {
      background-image: url(../img/general/img_garbage_lamp.svg);
      animation: fadeFallingDown 7s linear 4.2s infinite;
    }

    &--bottle {
      background-image: url(../img/general/img_garbage_bottle.svg);
      animation: fadeFallingDown 7s linear 6.6s infinite;
    }
  }

  &--left {
    left: 7%;
    width: 262px;

    @include respond-to(desktop) {
      width: 182px;
    }

    @include respond-to(laptop) {
      left: 3%;
      width: 150px;
    }

    @include respond-to(small_tablet) {
      left: 20px;
      width: 100px;
    }

    .garbage__img {
      height: 39.8vh;
      min-height: 230px;

      @include respond-to(laptop) {
        height: 246px;
        min-height: initial;
      }

      @include respond-to(small_tablet) {
        height: 164px;
      }

      @include respond-to(fablet) {
        animation-duration: 22s;
      }
    }
  }

  &--right {
    right: 7%;
    width: 270px;

    @include respond-to(desktop) {
      width: 190px;
    }

    @include respond-to(laptop) {
      right: 3%;
      width: 150px;
    }

    @include respond-to(small_tablet) {
      right: 20px;
      width: 100px;
    }

    .garbage__img {
      height: 35.74vh;
      min-height: 286px;

      @include respond-to(laptop) {
        height: 215px;
        min-height: initial;
      }

      @include respond-to(small_tablet) {
        height: 143px;
      }

      @include respond-to(fablet) {
        animation-duration: 17s;
      }
    }
  }
}

.article {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 185px;
  padding-bottom: 60px;
  box-sizing: border-box;

  @media only screen and (min-width: 1170px) and (max-height: 930px) {
    padding-top: 95px;
    padding-bottom: 30px;
  }

  @include respond-to(laptop) {
    padding-top: 245px;
    padding-bottom: 30px;
  }

  .text {

    &:not(:first-child) {
      margin-top: 30px;
    }
  }

  blockquote {

    &:not(first-child) {
      margin-top: 30px;
    }
  }

  .scroll-img {
    margin-top: 60px;

    @media only screen and (min-width: 1170px) and (max-height: 930px) {
      margin-top: 40px;
    }
  }

  &__body {
    position: relative;
    z-index: 15;
  }

  &__title {
    font-size: 24rem;
    font-weight: 900;

    @media only screen and (min-width: 1170px) and (max-height: 930px) {
      font-size: 10rem;
    }

    @include respond-to(laptop) {
      font-size: 12rem;
    }

    @include respond-to(small_tablet) {
      font-size: 7rem;
    }

    span {
      display: block;
      font-size: 4.8rem;
      line-height: 1;
      letter-spacing: 0.03em;

      @media only screen and (min-width: 1170px) and (max-height: 930px) {
        font-size: 4rem;
      }

      @include respond-to(laptop) {
        font-size: 3.6rem;
      }

      @include respond-to(small_tablet) {
        font-size: 2.4rem;
      }
    }
  }

  &__desc {
    margin-top: 40px;
  }

  &__video {
    margin-top: 40px;
  }

  &__footer {
    margin-top: auto;
    padding-top: 40px;
    text-align: center;

    @include respond-to(laptop) {
      padding-top: 270px;
    }

    @include respond-to(small_tablet) {
      padding-top: 230px;
    }

    .text {
      font-size: 1rem;
      color: #666;
    }
  }
  
  &--tips {
    padding-top: 60px;
  
    @include respond-to(laptop) {
      padding-top: 245px;
    }

    .article {

      &__title {
        font-size: 22rem;
  
        @media only screen and (min-width: 1170px) and (max-height: 930px) {
          font-size: 10rem;
        }
  
        @include respond-to(laptop) {
          font-size: 12rem;
        }
    
        @include respond-to(small_tablet) {
          font-size: 7rem;
        }

        span {

          @media only screen and (min-width: 1170px) and (max-height: 930px) {
            font-size: 3rem;
          }
        }
      }

      &__footer {

        @include respond-to(small_tablet) {
          padding-top: 270px;
        }
      }
    }

    .text {

      @media only screen and (min-width: 1170px) and (max-height: 930px) {
        font-size: 1.4rem;
      }
    }
  }

  &--cleanup {

    @include respond-to(laptop) {
      padding-bottom: 350px;
    }

    @include respond-to(phone) {
      padding-bottom: 400px;
    }
  }

  &--green-side {

    @include respond-to(fablet) {
      padding-bottom: 350px;
    }
  }

  &--report {

    @include respond-to(1279px) {
      padding-top: 360px;
      padding-bottom: 60px;
    }

    @include respond-to(laptop) {
      padding-bottom: 350px;
    }

    @include respond-to(small_tablet) {
      padding-top: 300px;
    }

    // @include respond-to(fablet) {
    //   padding-top: 220px;
    // }
  }

  &--video {
    padding-top: 110px;
    padding-bottom: 110px;

    @include respond-to(phone) {
      padding-bottom: 300px;
    }
  }
}

.scroll-img {
  width: 77px;
  height: 33px;
  background-image: url(../img/general/icon/icon_scroll.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  // animation: moveRightLeft 1.3s linear infinite;

  @include respond-to(laptop) {
    display: none;
  }
}

.image-clip {
  overflow: hidden;

  &--hexagon {
    width: 360px;
    height: 415px;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);

    @include respond-to(laptop) {
      width: 220px;
      height: 250px;
    }

    @include respond-to(small_tablet) {
      width: 170px;
      height: 195px;
    }
  }

  &--octagon {
    width: 344px;
    height: 344px;
    clip-path: polygon(50% 0%, 85% 15%, 100% 50%, 85% 85%, 50% 100%, 15% 85%, 0% 50%, 15% 15%);

    @include respond-to(laptop) {
      width: 250px;
      height: 250px;
    }

    @include respond-to(small_tablet) {
      width: 150px;
      height: 150px;
    }

    &-xl {
      width: 505px;
      height: 505px;

      @media only screen and (min-width: 1170px) and (max-height: 930px) {
        width: 336px;
        height: 336px;
      }

      @include respond-to(laptop) {
        width: 238px;
        height: 238px;
      }
    }

    &-md {
      flex-shrink: 0;
      // width: 440px;
      width: 22.917vw;
      // height: 440px;
      height: 22.917vw;

      @media only screen and (min-width: 1170px) and (max-height: 930px) {
        width: 250px;
        height: 250px;
      }

      @include respond-to(laptop) {
        width: 250px;
        height: 250px;
      }

      @include respond-to(fablet) {
        width: 150px;
        height: 150px;
      }
    }

    &-xs {
      width: 296px;
      height: 296px;

      @media only screen and (min-width: 1170px) and (max-height: 930px) {
        width: 196px;
        height: 196px;
      }

      @include respond-to(laptop) {
        width: 139px;
        height: 139px;
      }
    }

    &-xxs {
      width: 233px;
      height: 233px;

      @media only screen and (min-width: 1170px) and (max-height: 930px) {
        width: 155px;
        height: 155px;
      }

      @include respond-to(laptop) {
        width: 110px;
        height: 110px;
      }
    }
  }

  &--heptagon {
    width: 195px;
    height: 190px;
    clip-path: polygon(50% 0%, 90% 20%, 99% 60%, 72% 100%, 28% 100%, 1% 59%, 10% 20%);

    @media only screen and (min-width: 1170px) and (max-height: 930px) {
      width: 123px;
      height: 120px;
    }

    @include respond-to(laptop) {
      width: 123px;
      height: 120px;
    }
  }
}

.inset-decor {
  position: relative;
  z-index: 20;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    width: 18px;
    height: 100%;
    background-image: url(../img/general/img_triangle.png);
    background-size: auto;
    background-repeat: round;

    @include respond-to(laptop) {
      display: none;
    }
  }
  
  &--inverse {

    &::before {
      left: -17px;
      transform: rotate(180deg);
      background-position: top -10px left;
    }
  }

  &--hor {

    &::before {
      top: auto;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 18px;
      background-image: url(../img/general/img_triangle_hor.png);
      background-repeat: round;
    }

    &-top {

      &::before {
        left: 0;
        width: 100%;
        height: 18px;
        background-image: url(../img/general/img_triangle_hor.png);
        transform: rotate(180deg);
        background-repeat: round;
      }
    }
  }
}

.slide {
  display: flex;
  height: 100%;
  padding: 145px 7.813vw 30px;
  box-sizing: border-box;

  @media only screen and (min-width: 1170px) and (max-height: 930px) {
    padding: 95px 55px 30px;
  }

  @include respond-to(laptop) {
    flex-wrap: wrap;
    padding: 75px 100px;
  }

  @include respond-to(small_tablet) {
    padding: 50px 20px;
  }

  &__item {

    &:first-child {
      flex-shrink: 0;
      padding-top: 50px;

      @media only screen and (min-width: 1170px) and (max-height: 930px) {
        display: flex;
        flex-direction: column;
        width: 28%;
        padding-top: 0;
      }

      @include respond-to(laptop) {
        padding-top: 0;
      }

      .image-clip {
        display: none;
        margin-left: auto;

        @media only screen and (min-width: 1170px) and (max-height: 930px) {
          display: block;
        }

        &--octagon {

          &-md {
            width: 200px;
            height: 200px;
          }
        }
      }
    }

    &:nth-child(2) {
      // margin-left: 70px;
      margin-left: 3.646vw;
      padding-top: 50px;

      @media only screen and (min-width: 1170px) and (max-height: 930px) {
        display: none;
        margin-left: 15px;
      }

      @include respond-to(laptop) {
        padding-top: 0;
      }

      @include respond-to(phone) {
        width: 100%;
        margin-top: -25px;
        margin-left: initial;
      }

      .image-clip {

        @include respond-to(phone) {
          margin-left: auto;
        }
      }
    }

    &:last-child {
      // margin-left: 100px;
      margin-left: 5.208vw;
      overflow: auto;

      @media only screen and (min-width: 1170px) and (max-height: 930px) {
        margin-left: 50px;
      }

      @include respond-to(laptop) {
        width: 100%;
        margin-top: 60px;
        margin-left: 0;
      }

      @include respond-to(phone) {
        margin-top: -80px;
      }
    }
  }

  &__count {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    // width: 120px;
    width: 6.250vw;
    // height: 120px;
    height: 6.250vw;
    border-radius: 50%;
    background-color: $flamingo;

    @media only screen and (min-width: 1170px) and (max-height: 930px) {
      width: 80px;
      height: 80px;
      margin-top: -70px;
    }

    @include respond-to(laptop) {
      width: 80px;
      height: 80px;
    }

    &::before {
      counter-increment: count;
      content: counter(count);
      font-family: $raleway;
      // font-size: 7.2rem;
      font-size: 3.750vw;
      font-weight: 800;
      line-height: 150%;
      transform: translateY(-6px);

      @media only screen and (min-width: 1170px) and (max-height: 930px) {
        font-size: 4.4rem;
        transform: translateY(-3px);
      }

      @include respond-to(laptop) {
        font-size: 4.4rem;
        transform: translateY(-3px);
      }
    }
  }

  &__title {
    margin-top: 30px;
    // font-size: 7.2rem;
    font-size: 3.750vw;
    font-weight: 800;

    @media only screen and (min-width: 1170px) and (max-height: 930px) {
      font-size: 4rem;
    }

    @include respond-to(laptop) {
      margin-top: 20px;
      font-size: 3.6rem;
    }

    @include respond-to(phone) {
      font-size: 2.6rem;
    }

    & > span {
      position: relative;
      display: block;
      // font-size: 30rem;
      font-size: 15.625vw;
      line-height: 1;

      @media only screen and (min-width: 1170px) and (max-height: 930px) {
        font-size: 18rem;
      }

      @include respond-to(laptop) {
        font-size: 14.4rem;
      }

      span {
        position: absolute;
        bottom: 2px;
        left: 0;
        // font-size: 3.6rem;
        font-size: 1.875vw;
        text-transform: initial;

        @media only screen and (min-width: 1170px) and (max-height: 930px) {
          font-size: 2rem;
        }

        @include respond-to(laptop) {
          font-size: 1.4rem;
        }
      }
    }
  }

  &__icon {
    width: 120px;
    height: 120px;
    margin-bottom: 40px;
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;

    @media only screen and (min-width: 1170px) and (max-height: 930px) {
      width: 80px;
      height: 80px;
      margin-bottom: 40px;
    }

    @include respond-to(laptop) {
      width: 60px;
      height: 60px;
      margin-bottom: 30px;
    }

    &--food-waste {
      background-image: url(../img/general/icon/icon_001.svg);
    }

    &--paper {
      background-image: url(../img/general/icon/icon_002.svg);
    }

    &--plastic {
      background-image: url(../img/general/icon/icon_003.svg);
    }

    &--glass {
      background-image: url(../img/general/icon/icon_004.svg);
    }

    &--waste {
      background-image: url(../img/general/icon/icon_005.svg);
    }
    
    &--subbotniks {
      background-image: url(../img/general/icon/icon_008.svg);
    }

    &--plasticwatching {
      background-image: url(../img/general/icon/icon_009.svg);
    }

    &--ecotourism {
      background-image: url(../img/general/icon/icon_010.svg);
    }

    &--zero-waste {
      background-image: url(../img/general/icon/icon_011.svg);
    }

    &--education {
      background-image: url(../img/general/icon/icon_012.svg);
    }
  }

  &__text {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 150%;

    @media only screen and (min-width: 1170px) and (max-height: 930px) {
      font-size: 1.4rem;
    }

    @include respond-to(laptop) {
      font-size: 1.6rem;
    }

    &:first-of-type {
      font-weight: 700;
    }

    &:not(:first-of-type) {
      margin-top: 20px;
    }

    a {
      color: #3caea3;
    }
  }

  &--tips {
    padding-top: 130px;

    @media only screen and (min-width: 1170px) and (max-height: 930px) {
      padding-top: 95px;
    }
  
    @include respond-to(laptop) {
      padding-top: 75px;
    }
  
    @include respond-to(small_tablet) {
      padding-top: 50px;
    }

    .slide {

      &__item {

        &:first-child {
          width: 55%;

          @media only screen and (min-width: 1170px) and (max-height: 930px) {
            width: 33%;
            padding-top: 50px;
          }

          @include respond-to(laptop) {
            width: 65%;
          }

          @include respond-to(tablet) {
            width: 100%;
          }
        }

        &:last-child {
          padding-top: 0;

          @media only screen and (min-width: 1170px) and (max-height: 930px) {
            display: block;
          }

          @include respond-to(laptop) {
            margin-top: -50px;
          }

          @include respond-to(small_tablet) {
            margin-top: -25px;
          }
        }

        .image-clip {
          display: block;
          width: 22.917vw;
          height: 22.917vw;
          margin-top: 25px;
          // margin-left: auto;

          @media only screen and (min-width: 1170px) and (max-height: 930px) {
            width: 270px;
            height: 270px;
          }
    
          @include respond-to(laptop) {
            width: 250px;
            height: 250px;
            margin-top: 0;
          }
    
          @include respond-to(small_tablet) {
            width: 150px;
            height: 150px;
            margin-top: 10px;
          }
        }

        .slide {

          &__count {

            @media only screen and (min-width: 1170px) and (max-height: 930px) {
              margin-top: 0;
            }
          }

          &__title {

            @media only screen and (min-width: 1170px) and (max-height: 930px) {
              font-size: 3.8rem;
            }
          }
        }
      }

      &__text {

        @media only screen and (min-width: 1170px) and (max-height: 930px) {
          font-size: 1.4rem;
        }
      }
    }
  }
}

.infograp {
  display: flex;
  height: 100%;

  @include respond-to(laptop) {
    flex-direction: column;
  }

  &__item {
    width: 50%;
    box-sizing: border-box;

    @include respond-to(laptop) {
      width: 100%;
    }

    &:first-child {
      max-width: 907px;

      @media only screen and (min-width: 1170px) and (max-height: 930px) {
        max-width: 500px;
      }

      @include respond-to(laptop) {
        position: relative;
        padding-top: 40%;
        max-width: initial;
        height: 0;
      }

      @include respond-to(small_tablet) {
        padding-top: 47%;
      }

      @include respond-to(fablet) {
        padding-top: 63%;
      }

      @include respond-to(phone) {
        padding-top: 94%;
      }

      img {
        object-position: right;

        @include respond-to(laptop) {
          position: absolute;
          top: 0;
          left: 0;
          object-position: center;
        }
      }
    }

    &:last-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 100px;

      @media only screen and (min-width: 1170px) and (max-height: 930px) {
        width: 70%;
        padding-right: 50px;
        padding-left: 50px;
      }

      @include respond-to(laptop) {
        padding-top: 50px;
        padding-bottom: 0;
      }

      @include respond-to(small_tablet) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .text:not(:first-of-type) {
        margin-top: 20px;
      }
    }
  }

  &__btn {
    margin-top: 40px;

    @media only screen and (min-width: 1170px) and (max-height: 930px) {
      margin-top: 30px;
    }

    @include respond-to(laptop) {
      margin-top: 30px;
    }

    &-item {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 40px;
        margin-bottom: 50px;

        @media only screen and (min-width: 1170px) and (max-height: 930px) {
          margin-top: 20px;
        }

        @include respond-to(laptop) {
          margin-top: 20px;
        }
      }
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @media only screen and (min-width: 1170px) and (max-height: 930px) {
      width: 80px;
      height: 80px;
    }

    @include respond-to(laptop) {
      width: 80px;
      height: 80px;
    }

    &--pot {
      background-image: url(../img/general/icon/icon_006.svg);
    }

    &--bottle {
      background-image: url(../img/general/icon/icon_007.svg);
    }
  }

  &__title {
    margin-left: 30px;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-align: left;
  }
}

.count-list {

  &:not(:first-child) {
    margin-top: 120px;

    @include respond-to(laptop) {
      margin-top: 60px;
    }
  }

  &__item {
    display: flex;

    @include respond-to(laptop) {
      position: relative;
      padding-top: 100px;
    }

    &:not(:first-child) {
      margin-top: 120px;

      @include respond-to(laptop) {
        margin-top: 60px;
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      @include respond-to(laptop) {
        flex-direction: initial;
      }

      .count-list {

        &__col {

          &:first-child {
            margin-left: -20px;

            @include respond-to(laptop) {
              margin-left: 0;
            }
          }
        }
      }
    }

    .count {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: $flamingo;

      @include respond-to(laptop) {
        top: -100px;
        width: 80px;
        height: 80px;
      }

      &::before {
        counter-increment: count;
        content: counter(count);
        font-family: $raleway;
        font-size: 6.4rem;
        font-weight: 800;
        transform: translateY(-6px);

        @include respond-to(laptop) {
          font-size: 4.8rem;
        }
      }
    }

    p {

      &:not(:first-child) {
        margin-top: 30px;
      }
    }
  }

  &__col {
    position: relative;

    &:first-child {
      padding-left: 130px;

      @include respond-to(laptop) {
        padding-left: 0;
      }
    }

    &:last-child {
      display: flex;

      @include respond-to(small_tablet) {
        display: none;
      }
    }
  }

  &__title {
    text-transform: initial;
  }

  &__img {
    margin-top: 85px;
    width: 450px;
    height: 450px;
    clip-path: polygon(50% 0%, 85% 15%, 100% 50%, 85% 85%, 50% 100%, 15% 85%, 0% 50%, 15% 15%);

    @include respond-to(laptop) {
      width: calc(max(200px, 25vw));
      height: calc(max(200px, 25vw));
      margin-top: 0;
    }

    &.mobile {
      display: none;
      margin-top: 30px;

      @include respond-to(small_tablet) {
        display: block;
      }
    }

    &--md {
      align-self: center;
      width: 350px;
      height: 350px;
      margin-top: 0;

      @include respond-to(laptop) {
        align-self: initial;
        width: calc(max(200px, 25vw));
        height: calc(max(200px, 25vw));
      }
    }

    &--xs {
      align-self: center;
      width: 300px;
      height: 300px;
      margin-top: 0;

      @include respond-to(laptop) {
        align-self: initial;
        width: calc(max(200px, 25vw));
        height: calc(max(200px, 25vw));
      }
    }
  }
}

.ribbon {
  position: relative;
  padding: 12px 35px;
  box-sizing: border-box;
  background-color: $yellow;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 100%;
    background-image: url(../img/general/img_triangle_black.png);
    background-size: auto;
    background-repeat: round;
  }

  &__img {
    width: 255px;
    height: 180px;
    margin-left: auto;

    @include respond-to(hd) {
      width: 187px;
      height: 133px;
    }

    @include respond-to(fablet) {
      width: 125px;
      height: 89px;
    }
  }

  &--absolute {
    position: absolute;
    top: 15%;
    left: 0;
    z-index: 10;
    width: calc(max(290px, 28vw));

    @include respond-to(hd) {
      width: calc(max(290px, 22vw));
    }

    @include respond-to(1279px) {
      width: 400px;
      padding-top: 9px;
      padding-bottom: 9px;
    }

    @include respond-to(laptop) {
      top: 10%;
    }

    @include respond-to(fablet) {
      top: 8%;
      width: calc(max(143px, 50vw));
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 6px;
    }

    &::before {

      @include respond-to(hd) {
        width: 14px;
        background-size: 95%;
      }

      @include respond-to(fablet) {
        width: 9px;
        background-size: 90%;
      }
    }
  }

  &--both {

    &-side {

      &::before {
        transform: rotate(180deg);
        background-image: url(../img/general/img_triangle.png);

        @include respond-to(small_tablet) {
          width: 18px;
          background-size: auto;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 100%;
        background-image: url(../img/general/img_triangle.png);
        background-size: auto;
        background-repeat: round;
      }
    }
  }
}

.blockquote {
  width: 1140px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 30px;
  box-sizing: border-box;

  @include respond-to(small_tablet) {
    padding: 0 15px;
  }

  &:not(:first-child) {
    margin-top: 120px;

    @include respond-to(small_tablet) {
      margin-top: 60px;
    }
  }

  &:nth-child(even) {
    
    .quote {
      flex-direction: row-reverse;

      @include respond-to(tablet) {
        flex-direction: column;
      }

      &__item {

        &:not(:first-child) {
          margin-left: 0;
          margin-right: 40px;

          @include respond-to(tablet) {
            margin-right: 0;
          }
        }
      }

      figcaption {
        text-align: left;

        @include respond-to(tablet) {
          text-align: right;
        }
      }
    }
  }
}

.quote {
  display: flex;

  @include respond-to(tablet) {
    flex-direction: column;
  }

  blockquote {
    font-size: 2rem;
    font-style: italic;
    line-height: 150%;

    @include respond-to(tablet) {
      font-size: 1.8rem;
    }

    p {

      &:not(:first-child) {
        margin-top: 15px;
      }
    }
  }

  figcaption {
    margin-top: 20px;
    text-align: right;
  }

  &__item {

    &:not(:first-child) {
      margin-left: 40px;

      @include respond-to(tablet) {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }

  &__quotes {
    width: 82px;
    height: 60px;
    background-image: url(../img/general/icon/icon_quotes.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.ny-game-link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  border-top: 2px solid $keppel;
  border-bottom: 2px solid $keppel;

  @include respond-to(fablet) {
    flex-direction: column;
    padding: 10px 0;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    background-image: url(../img/general/icon/icon_snowflakes.svg);
    background-position: center;
    background-size: contain;
    background-repeat: inherit;
  }

  a {
    display: inline-block;
    margin: 0 20px;
    font-family: $raleway;
    font-size: 1.6rem;
    line-height: 150%;
    text-transform: uppercase;
    color: $white;

    @include respond-to(fablet) {
      margin: 5px 0;
    }

    &:hover {
      color: $ming;
    }

    br {
      display: none;

      @include respond-to(fablet) {
        display: block;
      }
    }

    span {
      font-weight: 700;
      letter-spacing: 0.03em;
    }
  }
}

@keyframes fadeFallingDown {
  0% {
    top: -500px;
    opacity: 0;
    visibility: hidden;
    
    // transform: translateY(0);
  }

  35% {
    opacity: 1;
    visibility: visible;
  }

  90% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    // transform: translateY(100%);
    top: 120%;
  }
}

@keyframes moveRightLeft {
  0% {
    transform: translate3d(-10px, 0, 0);
  }
  50% {
    transform: translate3d(10px, 0, 0);
  }
  100% {
    transform: translate3d(-10px, 0, 0);
  }
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

