// Device dimensions

$phone: 320px;
$xxs: 374px;
$xs: 479px;
$mobile: 639px;
$sm: 767px;
$md: 1024px;
$wd: 1169px;
$lg: 1439px;
$xxl: 1919px;

// Colors

$black: #000;
$biscay: #173f5f;
$flamingo: #ed553b;
$keppel: #3caea3;
$matisse: #20639b;
$mineShaft: #252525;
$ming: #3d8185;
$white: #fff;
$yellow: #f6d55c;

$overlay: rgba($black, 0.5);

// Font size

$basicFontSize: 10px;
$raleway: 'Raleway', 'Helvetica', sans-serif;
$robotoSlab: 'Roboto Slab', 'Helvetica', serif;

//others

$basicTransition: all 0.3s;
