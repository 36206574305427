/* Your custom fonts here */

@font-face {
  font-family: 'Roboto Slab';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/RobotoSlab/RobotoSlab-Light.woff2") format("woff2"), url("../fonts/RobotoSlab/RobotoSlab-Light.woff") format("woff");
}

@font-face {
  font-family: 'Roboto Slab';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/RobotoSlab/RobotoSlab-Regular.woff2") format("woff2"), url("../fonts/RobotoSlab/RobotoSlab-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Roboto Slab';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/RobotoSlab/RobotoSlab-Bold.woff2") format("woff2"), url("../fonts/RobotoSlab/RobotoSlab-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Raleway';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Raleway/Raleway-Regular.woff2") format("woff2"), url("../fonts/Raleway/Raleway-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Raleway';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Raleway/Raleway-Bold.woff2") format("woff2"), url("../fonts/Raleway/Raleway-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Raleway';
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Raleway/Raleway-ExtraBold.woff2") format("woff2"), url("../fonts/Raleway/Raleway-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: 'Raleway';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Raleway/Raleway-Black.woff2") format("woff2"), url("../fonts/Raleway/Raleway-Black.woff") format("woff");
}