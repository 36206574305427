@import "variables";
@import "mixins";

html {
  min-height: 100%;
  font-size: $basicFontSize;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
ol,
ul,
input,
button,
textarea,
blockquote,
figure {
  padding: 0;
  margin: 0;
}

body {
  max-width: 2560px;
  margin: 0 auto;
  height: 100%;
  font-family: $robotoSlab;
  color: $mineShaft;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;

  &.inactive {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  transition: $basicTransition;

  &:link {
    text-decoration: none;
  }
}

img {
  display: block;
  width: 100%;
  height: auto;

  &.cover {
    height: 100%;
    object-fit: cover;
  }

  &.contain {
    height: 100%;
    object-fit: contain;
  }
}

button,
input {
  border: none;
  background: none;
  outline: none;
}

button {

  &.disable {
    pointer-events: none;
  }
}

.text {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 150%;

  a {
    color: $keppel;

    &:hover {
      color: $ming;
    }
  }

  strong {
    font-weight: bold;
  }

  &--xs {
    font-size: 1.4rem;
  }

  &--xxs {
    font-size: 1.2rem;
    line-height: 140%;
  }

  &--normal {
    font-weight: 400;
  }

  &--bold {
    font-weight: 700;
  }

  &--bullet {

    &-icon {
      position: relative;

      @include respond-to(small_tablet) {
        padding-top: 45px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -70px;
        width: 50px;
        height: 35px;
        background-image: url(../img/general/icon/icon_hand.svg);
        background-position: center;
        background-size: auto;
        background-repeat: no-repeat;

        @include respond-to(small_tablet) {
          left: 0;
        }
      }
    }
  }
}

.title,
.subtitle {
  font-family: $raleway;
  line-height: 120%;
  text-transform: uppercase;
}

.title {

  span {
    display: inline-block;
    font-size: 4.8rem;
    line-height: 1;
    letter-spacing: 0.03em;

    @media only screen and (min-width: 1170px) and (max-height: 930px) {
      font-size: 4rem;
    }

    @include respond-to(laptop) {
      font-size: 3.6rem;
    }

    @include respond-to(small_tablet) {
      font-size: 2.4rem;
    }
  }

  &--md {
    font-size: 7.2rem;
    font-weight: 900;

    @include respond-to(laptop) {
      font-size: 6.4rem;
    }

    @include respond-to(small_tablet) {
      font-size: 3.6rem;
    }
  }

  &--mmd {
    font-size: 6rem;
    font-weight: 900;

    @include respond-to(laptop) {
      font-size: 4.8rem;
    }

    @include respond-to(small_tablet) {
      font-size: 3.6rem;
    }

    span {
      font-size: 4rem;

      @include respond-to(laptop) {
        font-size: 3.2rem;
      }

      @include respond-to(small_tablet) {
        font-size: 2.4rem;
      }
    }
  }

  &--xs {
    font-size: 3.6rem;
    font-weight: 800;

    @include respond-to(phone) {
      font-size: 2.4rem;
    }
  }
}

.container {
  position: relative;
  width: 1170px;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  @include respond-to(desktop) {
    padding: 0 50px;
  }

  @include respond-to(small_tablet) {
    padding: 0 20px;
  }

  &--md {
    width: 1080px;

    @include respond-to(laptop) {
      padding: 0 70px;
    }

    @include respond-to(small_tablet) {
      padding: 0 15px;
    }
  }

  &--mmd {
    width: 900px;

    @include respond-to(laptop) {
      padding: 0 30px;
    }

    @include respond-to(small_tablet) {
      padding: 0 15px;
    }
  }

  &--xs {
    width: 770px;

    @include respond-to(laptop) {
      padding: 0 92px;
    }

    @include respond-to(small_tablet) {
      padding: 0 15px;
    }
  }
}

.list {
  @include nl();
  margin-top: 120px;

  @include respond-to(laptop) {
    margin-top: 60px;
  }

  li {
    position: relative;
    padding-left: 20px;

    &:not(:first-child) {
      margin-top: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 7px;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $flamingo;
    }
  }
}

.grid {
  display: grid;
  grid-column-gap: 30px;

  &--col {

    &-4 {
      grid-template-columns: repeat(4, 1fr);

      @include respond-to(1279px) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 30px;
      }

      @include respond-to(fablet) {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }

      a {

        &:hover {
          color: $ming;
        }
      }
    }

    &-3 {
      grid-template-columns: repeat(3, 1fr);

      @include respond-to(fablet) {
        grid-template-columns: 1fr;
        grid-row-gap: 40px;
      }
    }
  }
}

.video {

  &__content {
    position: relative;
    height: 0;
    padding-top: 56.25%;
  }
  
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    outline: none;
  }
}

