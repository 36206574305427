@import "variables";
@import "mixins";

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 100px;
  box-sizing: border-box;

  @include respond-to(laptop) {
    position: absolute;
    padding: 15px 30px;
  }

  &--default {
    position: absolute;

    &.fixed {
      position: fixed;
      background-color: $white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      animation: moveDown 0.25s linear;
    }
  }

  &__item {

    &:not(:first-child) {
      margin-left: 40px;
    }
  }

  &.fixed {

    @include respond-to(laptop) {
      position: fixed;
      background-color: $white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      animation: moveDown 0.25s linear;
    }
  }
}

.nav {
  display: flex;
  align-items: center;

  &__item {

    &:not(:first-child) {
      margin-left: 30px;
    }

    &--home {
      display: block;
      width: 29px;
      height: 32px;

      &:hover {

        path {
          stroke: $ming;
        }
      }
    }

    path,
    circle {
      transition: $basicTransition;
    }
  }

  &--white {

    @media only screen and (min-width: 1170px) {

      .btn-rules {

        circle {
          stroke: $white;
        }
    
        path {
          fill: $white;
        }
      }

      .nav__item--home {

        path {
          stroke: $white;
        }
      }
  
      .btn-menu span {
        background-color: $white;
      }
    }
  }
}

@keyframes moveDown {
  0% {
    top: -100px;
  }
  100% {
    top: 0;
  }
}
