@import "variables";
@import "mixins";

.report {
  padding-top: 120px;
  padding-bottom: 40px;

  @include respond-to(small_tablet) {
    padding-top: 100px;
    padding-bottom: 20px;
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:first-child) {
      margin-top: 120px;

      @include respond-to(laptop) {
        margin-top: 80px;
      }

      @include respond-to(small_tablet) {
        margin-top: 60px;
      }
    }

    &__body {
      padding: 0 70px;

      @include respond-to(small_tablet) {
        padding: 0 15px;
      }
    }

    &__footer {
      margin-top: 100px;
      padding: 0 15px;
      text-align: center;

      @include respond-to(laptop) {
        margin-top: 65px;
      }
    }
  }

  &__nums {
    width: 545px;
    max-width: calc(100% - 30px);
    margin-top: 60px;

    @include respond-to(laptop) {
      margin-top: 40px;
    }

    @include respond-to(small_tablet) {
      margin-top: 30px;
    }

    &.ribbon {
      padding-top: 35px;
      padding-bottom: 35px;

      @include respond-to(phone) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

    &-title {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 150%;
      text-align: center;
    }
  }

  &__stats {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    @include respond-to(phone) {
      flex-direction: column;
      align-items: center;
    }

    &-item {
      display: flex;
      align-items: center;

      &:not(:first-child) {
        margin-left: 20px;

        @include respond-to(phone) {
          margin-top: 20px;
          margin-left: 0;
        }
      }
    }

    &-icon {
      height: 50px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      &.human {
        width: 19px;
        background-image: url(../img/general/icon/icon_human.svg)
      }

      &.bags {
        width: 42px;
        background-image: url(../img/general/icon/icon_bags.svg)
      }

      &.tons {
        width: 50px;
        background-image: url(../img/general/icon/icon_tons.svg)
      }
    }

    &-info {
      margin-left: 10px;

      .text {
        margin-top: 5px;
      }
    }
  }

  &__graph {
    width: 100%;
    margin-top: 80px;
    padding: 0 30px;
    box-sizing: border-box;

    @include respond-to(laptop) {
      margin-top: 60px;
    }

    @include respond-to(small_tablet) {
      margin-top: 40px;
      padding: 0 15px;
    }

    .graph {

      &__img {
        width: 1408px;
        max-width: 100%;
        height: auto;
        margin: 0 auto;

        @include respond-to(small_tablet) {
          display: none;
          width: calc(max(290px, 50vw));
        }
      }
    }

    .section {

      &__body {
  
        @include respond-to(small_tablet) {
          padding: 0;
        }
      }
    }
  }

  &__photos {
    display: flex;
    flex-wrap: wrap;
    width: 1173px;
    max-width: 100%;
    margin-top: 60px;

    @include respond-to(small_tablet) {
      margin-top: 30px;
    }
  }
}

.graph {

  &__img {

    &.mobile {
      display: none;

      @include respond-to(small_tablet) {
        display: block;
      }
    }
  }
}

.photos {

  &__item {
    position: relative;
    display: block;
    width: 20%;
    padding-top: 20%;
    overflow: hidden;

    @include respond-to(fablet) {
      width: 50%;
      padding-top: 50%;
    }

    &:hover {

      img {
        transform: scale(1.12);
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      transition: all ease-in 0.3s;
    }
  }
}

/* Magnific Popup CSS */

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 0.6;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {

  /* Remove all paddings around the image on small screen */

  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

.mfp-bg {
  background: $mineShaft;
  opacity: 1;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: pointer;
}

.mfp-image-holder .mfp-close {
  position: fixed;
  top: 45px;
  right: 40px;
  width: 40px;
  height: 40px;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  background-image: url(../img/general/icon/icon_cross.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transition: $basicTransition;

  @include respond-to(laptop) {
    top: 30px;
    right: 30px;
  }

  @include respond-to(small_tablet) {
    top: 10px;
    right: 20px;
  }
}

.mfp-zoom-out-cur {
  overflow: hidden;
}

.mfp-wrap {
  overflow: auto;
}

.mfp-container {

  @include respond-to(laptop) {
    height: auto;
    padding: 120px 30px 0;
  }

  @include respond-to(small_tablet) {
    padding: 130px 15px 0;
  }

  @include respond-to(small_phone) {
    padding-top: 100px;
  }

  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    padding: 80px 40px 0;
  }
}

.mfp-content {

  @include respond-to(laptop) {
    vertical-align: top;
  }

  figure {
    position: relative;
    width: 56.25vw;
    max-width: 1080px;
    padding-top: 66.5%;

    @include respond-to(tablet) {
      width: calc(100vw - 60px);
    }

    @include respond-to(small_tablet) {
      width: calc(100vw - 30px);
    }

    @include respond-to(fablet) {
      padding-top: 75%;
    }

    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      width: 56.25vw;
    }
  }

  .mfp-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: initial !important;
    padding: 0;
    object-fit: contain;
  }
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: default;

  &::after {
    background: transparent;
  }
}

.mfp-arrow {
  width: 34px;
  height: 60px;
  margin-top: -30px;
  background-image: url(../img/general/icon/icon_arrow_slider.svg) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  opacity: 1;
  transition: all 0.3s;

  @include respond-to(laptop) {
    margin-top: 30px;
  }

  @include respond-to(tablet) {
    top: auto;
    bottom: -90px;
  }

  @include respond-to(fablet) {
    width: 20px;
    height: 34px;
  }

  &:hover {
    opacity: 0.6;
  }

  &::before,
  &::after {
    display: none;
  }

  &-left {
    left: calc(19.8vw - 30px);
    transform: rotate(180deg);

    @include respond-to(tablet) {
      left: 30px;
    }

    @include respond-to(small_tablet) {
      left: 15px;
    }
  }

  &-right {
    right: calc(19.8vw - 30px);

    @include respond-to(tablet) {
      right: 30px;
    }

    @include respond-to(small_tablet) {
      right: 15px;
    }
  }
}

.mfp-bottom-bar {
  top: calc(100% + 30px);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 0;
  padding: 0 35px 20px;
  box-sizing: border-box;

  @include respond-to(tablet) {
    padding-bottom: 4px;
  }
}

.mfp-counter {
  position: relative;
  top: auto;
  right: auto;
  font-family: $raleway;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.03em;
  color: #9e9e9e;
}

.mfp-title {
  margin-top: 10px;
  padding-right: 0;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 150%;
  text-align: center;
  color: $white;

  @include respond-to(small_tablet) {
    font-size: 1.4rem;
  }

  @include respond-to(small_phone) {
    font-size: 1.2rem;
  }
}
