@import "variables";
@import "mixins";

$indentSnowflakes: calc((100vw - 51.3vw) / 4);

.ny-game {
  color: $white;
  background-color: $mineShaft;

  .popup-answer {

    &__img {
      width: 119px;
      height: 151px;
    }
  }

  .intro {

    .image-clip {

      &--octagon {
        width: 344px;
        height: 344px;

        @include respond-to(desktop) {
          width: 250px;
          height: 250px;
        }

        @include respond-to(small_tablet) {
          width: 150px;
          height: 150px;
        }
      }
  
      &--heptagon {
        width: 195px;
        height: 190px;

        @include respond-to(desktop) {
          width: 124px;
          height: 120px;
        }
      }
  
      &--hexagon {
        width: 355px;
        height: 415px;

        @include respond-to(desktop) {
          width: 220px;
          height: 250px;
        }
      }
    }

    .btn {
      margin-top: 40px;
    }

    &__img {
      position: absolute;
      z-index: 5;

      &--left {
        bottom: -30%;
        left: -60%;

        @include respond-to(desktop) {
          bottom: -50%;
          left: -20%;
        }

        @include respond-to(laptop) {
          left: -10%;
        }

        @include respond-to(small_tablet) {
          display: none;
        }
      }

      &--right {

        &-top {
          top: -23%;
          right: -45%;

          @include respond-to(desktop) {
            right: -30%;
          }

          @include respond-to(laptop) {
            right: -17%;
          }

          @include respond-to(small_tablet) {
            top: -160px;
            right: -40px;
          }
        }

        &-bottom {
          right: -60%;
          bottom: 18%;

          @include respond-to(desktop) {
            right: 10%;
            bottom: -25%;
          }

          @include respond-to(small_tablet) {
            right: -20px;
            bottom: -150px;
          }
        }
      }
    }

    &__title {
      font-size: 9.2rem;
      font-weight: 900;
      letter-spacing: 0.03em;

      @include respond-to(desktop) {
        font-size: calc(max(6.4rem, 6vw));
        line-height: 100%;
      }

      @include respond-to(small_tablet) {
        font-size: calc(max(3.6rem, 8vw));
      }
    }

    &__desc {
      margin-top: 20px;

      .text {

        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }

  .result {
    padding-top: 95px;
    padding-bottom: 95px;

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 570px;
      max-width: 100%;
      margin: 0 auto;
      text-align: center;
    }

    .share {
      margin-left: 30px;

      @include respond-to(small_tablet) {
        justify-content: center;
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
      }
    }

    &__item {

      &:not(:first-child) {
        margin-top: 80px;

        @include respond-to(small_tablet) {
          margin-top: 60px;
        }
      }
    }

    &__title {
      margin-top: 30px;
      font-size: 2.1rem;
      font-weight: 700;
      line-height: 120%;

      @include respond-to(small_tablet) {
        font-size: 1.6rem;
      }
    }

    &__btn {
      justify-content: flex-end;
      justify-content: center;
      margin-top: 30px;

      @include respond-to(tablet) {
        justify-content: center;
      }

      @include respond-to(small_tablet) {
        flex-wrap: wrap;
      }
     }
  }

  .question {
    margin-top: 0;

    &__img {
      position: absolute;
      z-index: 10;

      &--left {
        left: -26%;
        bottom: 0;

        @include respond-to(desktop) {
          left: -45%;
        }

        @include respond-to(tablet) {
          left: -25%;
        }

        @include respond-to(small_tablet) {
          display: none;
        }
      }

      &--right {

        &-top {
          top: 11%;
          right: -20%;

          @include respond-to(1599px) {
            right: -25%;
          }

          @include respond-to(desktop) {
            right: -37%;
          }

          @include respond-to(small_tablet) {
            right: -10%;
          }
        }

        &-bottom {
          right: -29%;
          bottom: 14%;

          @include respond-to(desktop) {
            right: -35%;
          }

          @include respond-to(tablet) {
            right: -20%;
          }

          @include respond-to(small_tablet) {
            right: 0;
            bottom: 60px;
          }
        }
      }
    }

    &__title {
      margin-top: 85px;
      font-family: $raleway;
      font-size: 2.1rem;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      text-align: center;

      @include respond-to(small_tablet) {
        margin-top: 50px;
        font-size: 1.8rem;
      }
    }

    &__footer {
      margin-top: auto;
      padding-top: 250px;
      text-align: center;

      @include respond-to(small_tablet) {
        padding-top: 200px;
      }

      .text {
        font-size: 1rem;
        color: #666;
      }
    }
  }

  .answers {
    margin-top: 40px;

    &__list {
      flex-direction: column;
      margin-top: 0;
      margin-left: 0;
    }

    &__item {
      height: auto;
      padding: 20px 65px;
      border-width: 2px;
      border-radius: 45px;
      text-align: left;

      @include respond-to(small_tablet) {
        padding-right: 25px;
        padding-left: 25px;
        text-align: center;
      }

      &:not(:first-child) {
        margin-top: 15px;
      }

      &.hidden {
        display: none;
      }
    }
  }

  .image-clip {

    &--octagon {
      width: 360px;
      height: 360px;

      @include respond-to(laptop) {
        width: 280px;
        height: 280px;
      }
    }

    &--heptagon {
      width: 270px;
      height: 264px;

      @include respond-to(desktop) {
        width: 220px;
        height: 216px;
      }

      @include respond-to(small_tablet) {
        width: 180px;
        height: 176px;
      }
    }

    &--hexagon {
      width: 170px;
      height: 200px;

      @include respond-to(laptop) {
        width: 120px;
        height: 150px;
      }
    }
  }
  
  &__intro,
  &__questions,
  &__result {
    position: relative;
    min-height: 100vh;
    box-sizing: border-box;
    overflow: hidden;

    .container {
      z-index: 15;
    }

    &.hidden{
      display: none;
    }
  }

  &__intro,
  &__result {
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    background-color: $mineShaft;
  }

  &__intro,
  &__questions {

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: none;
      background-position: center;
      background-size: auto;
      background-repeat: no-repeat;

      @include respond-to(small_tablet) {
        display: block;
      }
    }

    &::before {
      top: 100px;
      left: 16%;
      width: 105px;
      height: 112px;
      background-image: url(../img/content/game/bg/003.png);
    }

    &::after {
      bottom: 80px;
      left: 10%;
      width: 149px;
      height: 88px;
      background-image: url(../img/content/game/bg/004.png);
    }
  }

  &__intro {
    display: flex;
    align-items: center;
    padding-top: 245px;
    padding-bottom: 260px;
    background-image: url(../img/content/game/bg/001.png);

    @include respond-to(small_tablet) {
      padding-bottom: 200px;
      background-image: none;
    }
  }

  &__result {
    background-image: url(../img/content/game/bg/002.png);
  }

  &__questions {

    &-content {
      position: relative;
      z-index: 10;
      width: calc(max(570px, 51.3vw));
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      padding-top: 200px;
      padding-bottom: 60px;
      box-sizing: border-box;
      background-color: $mineShaft;

      @include respond-to(laptop) {
        padding-top: 260px;
        padding-bottom: 30px;
        background-color: transparent;
      }

      @include respond-to(small_tablet) {
        width: 100%;
        padding-top: 340px;
        padding-right: 15px;
        padding-left: 15px;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 18px;
        height: 100%;
        background-image: url(../img/general/img_triangle_black.png);
        background-size: auto;
        background-repeat: round;

        @include respond-to(laptop) {
          display: none;
        }
      }

      &::before {
        left: -17px;
      }

      &::after {
        right: -17px;
        transform: scale(-1, 1)
      }

      .inner {
        position: relative;
        width: 570px;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ed553b;
    transition: $basicTransition;

    @include respond-to(laptop) {
      display: none;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 18vw;
      height: 8.1vw;
      background-image: url(../img/general/img_snowflakes.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &::before {
      top: 28%;
      left: $indentSnowflakes;
      transform: translateX(-50%);
    }

    &::after {
      right: $indentSnowflakes;
      bottom: 37%;
      transform: translateX(50%);
    }
  }
}

.progress-bar {
  position: relative;
  width: 100%;
  max-width: 530px;
  height: 8px;
  margin: 0 auto;
  background-image: url(../img/content/game/progress-bar/001.svg);
  background-size: contain;
  background-repeat: no-repeat;

  @include respond-to(small_tablet) {
    width: 229px;
    height: 4px;
  }

  &__current {
    position: absolute;
    left: -21px;
    bottom: 7px;
    width: 48px;
    height: 80px;
    background-image: url(../img/content/game/progress-bar/002.svg);
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: margin 0.5s  linear, background-image 0.4s;

    @include respond-to(small_tablet) {
      left: -12px;
      bottom: 4px;
      width: 24px;
      height: 41px;
    }

    &.step {
      background-image: url(../img/content/game/progress-bar/006.svg);
    }
  }

  &__end {
    position: absolute;
    right: 0;
    bottom: 7px;
    width: 46px;
    height: 60px;
    background-image: url(../img/content/game/progress-bar/004.svg);
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.7s linear;

    @include respond-to(small_tablet) {
      right: -3px;
      bottom: 2px;
      width: 24px;
      height: 31px;
    }
  }
}

.facts {

  &__title {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.03em;
    text-align: center;
  }

  &__slider {
    position: relative;
    max-width: 710px;
    margin: 40px auto 0;
    padding: 40px 90px 65px;
    box-sizing: border-box;
    background-color: $yellow;

    @include respond-to(small_tablet) {
      padding: 40px 20px 30px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 12px;
      height: 100%;
      background-image: url(../img/general/img_triangle_yellow.png);
      background-size: auto;
      background-repeat: round;
    }

    &::before {
      left: -12px;
    }

    &::after {
      right: -12px;
      transform: scale(-1, 1)
    }

    .btn-arrow {
      position: absolute;
      top: 50%;
      z-index: 10;
      width: 20px;
      height: 40px;
      background-image: url(../img/general/icon/icon_arrow_black.svg);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;

      @include respond-to(small_tablet) {
        top: 95px;
      }

      &--prev {
        left: 20px;
        transform: translateY(-50%);

        @include respond-to(small_tablet) {
          transform: initial;
        }
      }

      &--next {
        right: 20px;
        transform: translateY(-50%) scale(-1, 1);

        @include respond-to(small_tablet) {
          transform: translateY(0) scale(-1, 1);
        }
      }
    }

    .slick {

      &-track {
        display: flex;
        align-items: center;
      }
    }
  }
}

.fact {
  display: flex !important;
  align-items: center;
  color: $mineShaft;

  @include respond-to(small_tablet) {
    flex-direction: column;
  }

  &__item {

    &:not(:first-child) {
      margin-left: 40px;

      @include respond-to(small_tablet) {
        margin-top: 40px;
        margin-left: 0;
        text-align: center;
      }
    }
  }

  &__img {
    width: 119px;
    height: 149px;
  }
}

.swing {

  &[data-animation='swing'] {
    transform-origin: top center;
    animation: swing 1.2s ease-in-out infinite alternate;
  }

  &[data-animation='swing-reverse'] {
    transform-origin: top center;
    animation: swing-reverse 1.2s ease-in-out infinite alternate;
  }
}

@keyframes swing {
  0% { 
    transform: rotate(1deg); 
  }
  100% { 
    transform: rotate(-1deg); 
  }
}

@keyframes swing-reverse {
  0% { 
    transform: rotate(-1deg);
  }
  100% { 
    transform: rotate(1deg); 
  }
}
